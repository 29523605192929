import './App.css';
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import Home from './components/Home';

function App() {
    
  return (
    <Router>
      <Switch>
        <Route path="/" search="" exact render={(props) => <Home {...props} />} />
      </Switch>
    </Router>
  );
}

export default App;
