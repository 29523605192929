import React, {useEffect, useState} from 'react'
import logo from '../logo.svg';

// const IDP_ID = "idp1"
// const SP_ID = "sp1"
// const CRYPTR_SAML_BASE = 'https://samly.howto:4443'
// const IDP_ID = "decat_Tk3ByP8U7wDXizHs2ojtgC"
// const SP_ID = "sp2"
const IDP_ID = "decathlon_XbmMzBE4MaFxiqrnoN2GuE"
const SP_ID = "sarbacane_YQ27EQXTgVxdZ5fjRQS6iS"
const CRYPTR_SAML_BASE = 'https://sarbacane.authent.me'
const CRYPTR_BASE_URL = `${CRYPTR_SAML_BASE}/enterprise/${IDP_ID}/tokens`

const Home = ({location: {search}}) => {
  const [requestDone, setRequestDone] = useState(false)
  const [authorizationCode, setAuthorizationCode] = useState(null)
  const [error, setError] = useState("")
  const [tokens, setTokens] = useState(null)

  useEffect(() => {
    let [_first, authorization_code] = search.split("?authorization_code=")
    setAuthorizationCode(authorization_code)
  }, [search])

  useEffect(() => {
    if(!requestDone && authorizationCode) {
      fetch(`${CRYPTR_BASE_URL}?sp_id=${SP_ID}`, { 
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ authorization_code: authorizationCode })
       })
        .then(res => res.json())
        .then(
          (result) => {
            console.log("result")
            console.log(result)
            setRequestDone(true)
            setError(null)
            setTokens(result)
          }
        )
    }
  }, [requestDone, search, authorizationCode, error])

  useEffect(() => {
    if(error !== "") {
      console.error("here is error")
      console.error(error)
    }
  }, [error])

  const refreshPage = () => window.location.reload()

  if (authorizationCode) {
    return (
      <div className="App">
        <header className="App-header">
          <p>
            {requestDone ? "POST request done" : "Need to make a POST request with "}
          </p>
          <button onClick={() => refreshPage()} style={{ cursor: "pointer", padding: "1rem", borderRadius: "1rem", backgroundColor: "#1e88e5", color: "white"}}>Refresh page</button>
          Authorization code:  <pre><code style={{fontSize: "12px"}}>{authorizationCode}</code></pre>
          {tokens === null ? (<div style={{border: "1px solid green", padding: "1rem", color: "red"}}>Tokens non récupérés</div>) : (
            <div style={{width: "90%", margin: "0 5%"}}>
              <table>
                <thead>
                    <tr>
                        <th>Key</th>
                        <th>Value</th>
                    </tr>
                </thead>
                <tbody>
                  {Object.entries(tokens).map(([key, value]) => {
                    return (
                      <tr style={{ padding: "1rem", color: tokens.hasOwnProperty("error") ? "red" : "green" }}>
                        <td>{key} :</td>
                        <td style={{ fontSize: tokens.hasOwnProperty("error") ? "1.2rem" : "0.8rem", maxWidth: tokens.hasOwnProperty("error") ? "100%" : "100px" , display: "inline-block"}} >{value}</td>
                      </tr>
                    )
                  })}
                </tbody>
            </table>
            </div>
          )}
        </header>
      </div>
    )
  } else {
    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Edit <code>src/App.js</code> and save to reload.
          </p>
          <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn React
          </a>
        </header>
      </div>
    )
    
  }
}

export default Home